import * as VueRouter from "vue-router";
import {
  createAuthGuard
} from "@auth0/auth0-vue";

import NotFound from "@/views/NotFound";
import HomeView from "@/views/HomeView";
import ManageAuth0Accounts from "@/views/admin/ManageAuth0Accounts";
import ManageValidationInfo from "@/views/admin/ManageValidationInfo";
import DealerView from "@/views/DealerView";
import IdentitiesView from "@/views/IdentitiesView";
import ContactView from "@/views/ContactView.vue";
import ValidationView from "@/views/ValidationView.vue";
import LogoutView from "@/views/LogoutView.vue";
import LoginMenuView from "@/views/LoginMenuView.vue";
import AuthorizedRedirectView from "@/views/AuthorizedRedirectView.vue";
import ManageDealers from "@/views/admin/ManageDealers.vue";
import InternalErrorView from "@/views/InternalError.vue";



export function createRouter(app, auth) {
  const InnerAuthGuard = createAuthGuard({
    app,
    redirectLoginOptions: {
      async openUrl(url) {
        console.warn("Suppressing: " + url + " to redirect to menu-login")
        return
      }
    }
  })

  async function OurAuthGuard(to) {
    const auth0Test = await InnerAuthGuard(to)
    if (auth0Test) {
      return true // 1. Easy case, the cookies exist, we're good to go
    }

    // 2. Try to authorize silently
    if(! to.query.error?.length) {
      await auth.loginWithRedirect({
        authorizationParams: {
          prompt: 'none',
          redirect_uri: window.location.href,
          "rev-silent-mode": "true"
        }
      })

      return false
    }

    const cleanedUpUrl = new URL(window.location.origin + to.href);
    cleanedUpUrl.searchParams.delete('error');
    cleanedUpUrl.searchParams.delete('error_description');
    cleanedUpUrl.searchParams.delete('state');

    return {
      name: "Login Menu", // head to our login menu on failure,
      query: {
        redirect_uri: cleanedUpUrl.toString()
      }
    }
  }

  const routes = [{
      path: "/menu-login",
      name: "Login Menu",
      component: LoginMenuView
    },
    {
      path: "/authorized-redirect",
      name: "Authorized Redirect",
      component: AuthorizedRedirectView
    },
    {
      path: "/",
      name: "Home",
      component: HomeView,
      beforeEnter: OurAuthGuard,
      children: [{
          path: "/contact",
          name: "Contact Info",
          component: ContactView,
        },
        {
          path: "/dealer",
          name: "Dealer",
          component: DealerView,
        },
        {
          path: "/identities",
          name: "Linked Accounts",
          component: IdentitiesView,
        },
        {
          path: "/auth0-admin",
          name: "Auth0 Admin",
          component: ManageAuth0Accounts,
        },
        {
          path: "/validation-admin",
          name: "Validation Admin",
          component: ManageValidationInfo,
        },
        {
          path: "/dealers-admin",
          name: "Dealers Admin",
          component: ManageDealers,
        },
      ],
    },
    {
      path: "/validation",
      name: "validation",
      component: ValidationView,
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutView,
    },
    {
      path: "/internal-error",
      name: "Internal Error",
      component: InternalErrorView,
    },
    {
      path: "/profile",
      name: "profile",
      redirect: "/",
    },
    {
      path: "/:pathMatch(.*)*",
      name: "notfound",
      component: NotFound,
    },
  ];

  const history = VueRouter.createWebHistory(
    import.meta.env.BASE_URL);

  // const ogPush = Router.prototype.push;
  // Router.prototype.push = function push(location) {
  //   return ogPush
  //     .call(this, location)
  //     .catch((err) =>
  //       err.name === "NavigationDuplicated"
  //         ? Promise.resolve()
  //         : Promise.reject(err)
  //     );
  // };

  const router = VueRouter.createRouter({
    routes,
    history,
    scrollBehavior(to, _from, savedPosition) {
      if ("SelectFarm" === to.name && to.params.farmId) {
        const el = document.getElementById(
          `farm-accordion-${to.params.farmId}`
        );
        if (el) {
          el.scrollIntoView({
            block: "nearest",
            behavior: "smooth"
          });
        }
      }

      return null;
    },
  });

  // router.beforeEach((to, from, next) => {
  //   authGuard(to, from, next);
  // });

  let userNameAppended = false;

  router.afterEach(function () {
    if (!userNameAppended && auth.isAuthenticated.value) {
      document.title += " - " + auth.user.value.name;
      userNameAppended = true;
    }
  });

  return router;
}