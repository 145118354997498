<template>
  <template v-if="data.mode === Mode.ShowingCurrent">
    <div class="phone-container">
      <label for="phone">Phone</label>
      <input
        type="tel"
        id="phone"
        :placeholder="props.value"
        :value="props.value"
        required
        disabled
      />
      <button type="button" @click="beginEditingNewValue">
        <span class="material-icons">edit</span>
      </button>
    </div>
  </template>
  <template v-else-if="data.mode === Mode.EditingNewValue">
    <form @submit.prevent="confirmNewValue">
      <div class="phone-container editing">
        <label for="phone">Phone</label>
        <input
          type="tel"
          id="phone"
          ref="phoneInput"
          v-model="data.newValue"
          placeholder="Enter new phone"
          required
          autofocus
        />
        <button type="button" @click="confirmNewValue">
          <span class="material-icons">check</span>
        </button>
        <button type="button" @click="cancelEditing">
          <span class="material-icons">close</span>
        </button>
      </div>
    </form>
  </template>
  <template v-else-if="data.mode === Mode.ConfirmingNewValue">
    <form @submit.prevent="verifyOtp">
      <div class="phone-container editing">
        <label for="otp">Enter OTP</label>
        <input
          type="text"
          id="otp"
          ref="otpInput"
          v-model="data.code"
          placeholder="Enter OTP"
          inputmode="numeric"
          pattern="[0-9]*"
          required
          autofocus
        />
        <button type="button" @click="verifyOtp">
          <span class="material-icons">check</span>
        </button>
        <button type="button" @click="cancelEditing">
          <span class="material-icons">close</span>
        </button>
      </div>
      <div style="width: 100%" class="mt-1">
        <span class="details">
          A one-time-password message has been sent to your phone.
        </span>
      </div>
    </form>
  </template>

  <span class="details error mt-1" v-if="data.error">
    {{ data.error }}
  </span>
  <Modal :isOpen="data.blocked" @update:isOpen="updateBlocked" :closeButton="true" :forBlock="true">
    <div class="modal-content">
      <h2 :style="{ width: '100%', textAlign: 'center' }">Account Locked</h2>
      <p>
        Your account has been blocked due to multiple unsuccessful attempts to
        change your phone number. Our team has been notified and will review the
        situation.
      </p>
      <div class="contact-note mb-2">
        Support: <a href="tel:+15014900395">(501) 490-0395</a>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, ref, nextTick } from "vue";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";
import Modal from "./Modal.vue";

const Mode = Object.freeze({
  ShowingCurrent: 1,
  EditingNewValue: 2,
  ConfirmingNewValue: 3,
});

const props = defineProps({
  value: {
    required: true,
    type: String,
  },
});

const data = reactive({
  mode: Mode.ShowingCurrent,
  newValue: "",
  code: "",
  error: null,
  blocked: false,
});

const phoneInput = ref(null);
const otpInput = ref(null);

const $emit = defineEmits(["change"]);

const $api = inject("$api");
const $telemetry = inject("$telemetry");

function beginEditingNewValue() {
  data.mode = Mode.EditingNewValue;
  data.newValue = "";
  data.code = "";
  data.error = null;

  nextTick(() => {
    phoneInput.value?.focus();
  });
}

function cancelEditing() {
  data.mode = Mode.ShowingCurrent;
  data.error = null;
}
function userBlocked() {
  data.mode = Mode.ShowingCurrent;
  data.error = "You have been blocked from using this service";
  data.blocked = true;
}

async function confirmNewValue() {
  const parsed = parsePhoneNumberFromString(data.newValue, {
    defaultCountry: "US",
  });

  if (!parsed) {
    return;
  }

  if (!parsed.isValid()) {
    return;
  }

  data.mode = Mode.ConfirmingNewValue;
  data.code = "";
  data.newValue = parsed.number;

  nextTick(() => {
    otpInput.value?.focus();
  });

  try {
    data.error = null;

    await $api.postPhoneValidation({
      phone: parsed.number,
    });
  } catch (e) {
    $telemetry.error(e);
    data.mode = Mode.EditingNewValue;
    data.error = "Error Getting Validation Code -- Please Try Later";
  }
}

async function verifyOtp() {
  try {
    const result = await $api.putPhoneValidation({
      code: data.code,
    });
    if (result.success) {
      data.mode = Mode.ShowingCurrent;
      $emit("change", data.newValue); // we'll count on the parent component to pass in the updated value
      return;
    } else if (result.alreadyExists) {
      data.error = "Account with That Phone Exists";
      data.mode = Mode.ShowingCurrent;
      return;
    } else if (result.unexpectedError) {
      data.error = result.unexpectedError;
      data.mode = Mode.ShowingCurrent;
      return;
    } else if (result.expired) {
      data.error = "Validation Time Expired";
      data.mode = Mode.ShowingCurrent;
      return;
    }
    data.error = `Code Did Not Match -- Please Try Again`;
  } catch (e) {
    $telemetry.error(e);
    if (e.message.includes("Blocked User")) {
      userBlocked();
      return;
    }
    data.mode = Mode.EditingNewValue;
    data.error = "Error Validating Code -- Please Try Later";
  }
}
function updateBlocked(val) {
  data.blocked = val;
}
</script>

<style lang="css" scoped>
label {
  text-wrap: none;
}

.phone-container {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--padding) var(--padding);
  align-items: center;
  width: 100%;
}

.phone-container input:focus {
  border-color: var(--primary-color);
}

.phone-container button {
  height: 100%;
  min-width: 3rem;
  padding: 0;
}

.phone-container input {
  height: 100%;
  width: 12em;
  box-sizing: border-box;
  text-align: center;
}

#otp {
  width: 5em;
}

.contact-note {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
}
.modal-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.modal-content h2{
    margin-top: 0;
}
</style>
