import {
  provide,
  createApp,
  watch
} from 'vue'
import {
  createAuth0
} from '@auth0/auth0-vue'

import {
  createApi
} from '@/plugins/api'
import {
  TelemetryPlugin
} from '@/plugins/telemetry'
import App from './App.vue';
import {
  createRouter
} from './router';

import Loading from '@/components/Loading.vue'
import PaginationNav from '@/components/PaginationNav.vue'
import PhoneValidation from '@/components/PhoneValidation.vue'

async function main() {
  let env = {}
  try {
    const appEnvRequest = await fetch('/vue-app-env')
    env = await appEnvRequest.json()
  } catch (e) {
    alert('Server Down -- Refresh or Contact Support')
    return
  }

  // Vue.use(Auth0, {
  //   domain: env.VUE_APP_AUTH0_DOMAIN,
  //   clientId: env.VUE_APP_AUTH0_CLIENT_ID,
  //   audience: env.VUE_APP_AUTH0_API_AUDIENCE,
  //   redirectUri: window.location.origin,
  //   onRedirectCallback: (appState) => {
  //     console.log("Cleaning Up History");
  //     window.history.replaceState({}, document.title, window.location.pathname);

  //     const nextRoute =
  //       appState && appState.targetUrl
  //         ? appState.targetUrl
  //         : window.location.pathname;
  //     console.log("Redirecting to Next Route: " + nextRoute);

  //     router.push(nextRoute);
  //   },
  // });
  const app = createApp(App)

  app.component('Loading', Loading)
  app.component('PaginationNav', PaginationNav)
  app.component('PhoneValidation', PhoneValidation)

  app.provide('$env', env)

  const SENTRY_DSN = env['VUE_APP_SENTRY_DSN']
  const SENTRY_ENV = env['VUE_APP_SENTRY_ENV']
  const SENTRY_TRACING_SAMPLE_RATE = env['VUE_APP_SENTRY_TRACING_SAMPLE_RATE']

  const redirectUri = new URL('/', window.location.origin)
  console.log('Auth Redirect URI: ' + redirectUri)

  const auth = createAuth0({
    domain: env.VUE_APP_AUTH0_DOMAIN,
    clientId: env.VUE_APP_AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
    authorizationParams: {
      // connection: "email",
      // prompt: 'select_account',
      // display: "wap",oca
      // screen_hint: "login",
      // login_hint: "mpekar@revolutioncompany.com",
      audience: env.VUE_APP_AUTH0_API_AUDIENCE,
      redirect_uri: redirectUri
    },
    useRefreshTokens: true
  }, {
    // skipRedirectCallback: true
  })

  const router = createRouter(app, auth);

  app.use(TelemetryPlugin, {
    dsn: SENTRY_DSN,
    env: SENTRY_ENV,
    tracesSampleRate: SENTRY_TRACING_SAMPLE_RATE,
    router
  });

  const $telemetry = app.config.globalProperties.$telemetry




  watch(() => auth.user.value, () => {
    const user = auth?.user?.value || {}

    if (user?.sub) {
      $telemetry.setUser({
        id: user.sub,
        email: user.email
      })
    } else {
      $telemetry.setUser(null)
    }
  })

  app.use(createApi(auth))
  app.use(auth)

  app.use(router)
  app.mount('#app')
}

try {
  main();
} catch (e) {
  console.error(e)
}