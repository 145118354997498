import { reactive } from 'vue'


let ApiInstance = null
let AdminApiInstance = null

function InitApiInstance(auth0, defaultErrorCallback) {
  const Common = reactive({
    async fetchWrapper(url, method = 'GET', headers = {}, body, ignore404 = false) {
      let bearerToken = null

      try {
        bearerToken = await auth0.getAccessTokenSilently()
      }
      catch (e) {
        if(e?.error === "invalid_grant") {
          alert("invalid grant")
          auth0.logout()

          return null
        }

        if (e?.error) {
          console.warn("Fetch Auth0 Token Error: " + e.error + " for " + url)
          console.error(e)
          await auth0.logout()
          return new Promise((res, rej) => undefined) // don't resolve
        }
        else {
          console.error(e)

          throw e
        }
      }

      const finalHeaders = {
        Authorization: 'Bearer ' + bearerToken
      }

      Object.assign(finalHeaders, headers)

      let ret = await fetch(url, {
        method: method,
        headers: finalHeaders,
        body
      })

      if (defaultErrorCallback) {
        if (ret.status == 401) {
          defaultErrorCallback('CustomerInvalid')
          return
        }
      }

      if (!ret.ok) {
        if (ignore404 && (ret.status === 404)) {
          return ret
        }

        const text = await ret.text()
        throw new Error('GET Failed: ' + text)
      }

      return ret
    },

    async fetch(url, options) {
      if (!options.method) {
        throw 'options.method required'
      }

      return await this.fetchWrapper(
        url,
        options.method,
        options.headers || {},
        options.body || null
      )
    },

    async put(url, body) {
      return await this.fetchWrapper(
        url, 'PUT', {
          'Content-Type': 'application/json'
        }, body
      )
    },

    async post(url, body) {
      return await this.fetchWrapper(
        url, 'POST', {
          'Content-Type': 'application/json'
        }, body
      )
    },

    async delete(url, body) {
      return await this.fetchWrapper(url, 'DELETE')
    }
  })

  ApiInstance = reactive({
    async getUserInformation() {
      const response = await Common.fetchWrapper('/api/account')

      return response.json();
    },
    async getDealersForZip(zip5) {
      const response = await fetch(`/api/dealers/zipcode/${zip5}`)

      return response.json();
    },
    async getDealersForCity(city){
      const response = await fetch(`/api/dealers/city/${city}`)
      return response.json();
    },
    async getDealersForPosition(position){
      const response = await fetch(`/api/dealers/position/${position.latitude},${position.longitude}`);
      return response.json();
    },

    async putUserValidation(pcUserId, info) {
      const response = await Common.put(`/api/users/${pcUserId}`, JSON.stringify(info))

      return await response;
    },
 
    async deleteIdentity(provider, childId) {
      const response = await Common.delete(`/api/account/identities/${provider}/${childId}`)

      if (!response.ok) {
        throw response.statusText;
      }

      return response;
    },

    async postIdentity(childBearerToken) {
      const response = await Common.post(`/api/account/identities`,
        JSON.stringify({ childBearerToken })
      )

      if (!response.ok) {
        throw response.statusText;
      }

      return response;
    },

    async postPhoneValidation(payload) {
      const response = await Common.post(`/api/phone-validation`,
        JSON.stringify(payload)
      )

      if (!response.ok) {
        throw response.statusText;
      }

      return await response.json();
    },

    async putPhoneValidation(payload) {
      const response = await Common.put(`/api/phone-validation`,
        JSON.stringify(payload)
      )

      if (!response.ok) {
        throw response.statusText;
      }

      return response.json();
    },

    async putUserInformation(payload) {
      const response = await Common.put('/api/account', JSON.stringify(payload))

      if (!response.ok) {
        throw response.statusText;
      }

      return response;
    },
    async deleteUser() {
      const response = await Common.delete('/api/account')

      return response;
    }
  })

  AdminApiInstance = reactive({
    async fetch(...params) {
      return await Common.fetchWrapper(...params)
    },
    async getAllRebateEligibleDealers() {
      const response = await Common.fetchWrapper(`/api/dealers?rebate_eligible=true`)

      return response.json();
    },
    async getUsers(text) {
      const urlParams = new URLSearchParams({ text });
      const url = `/api/users?${urlParams.toString()}`

      const response = await Common.fetchWrapper(url)

      return response.json();
    },
    async getAuth0Users(params) {
      const urlParams = new URLSearchParams(params);
      const url = `/api/auth0/users?${urlParams.toString()}`

      const response = await Common.fetchWrapper(url)

      return response.json();
    },
    async putDealer(id, json) {
      const response = await Common.put(`/api/dealers/${id}`, JSON.stringify(json))

      return response.json();
    },
    async postAuth0User(json, urlParamsObj) {
      const response = await Common.post(`/api/auth0/users?${new URLSearchParams(urlParamsObj).toString()}`,
        JSON.stringify(json))

      return response.json();
    },
    async postAuth0Identity(parentId, childId) {

      const response = await Common.post(`/api/auth0/users/${encodeURIComponent(parentId)}/identities`,
        JSON.stringify({
          link_with: childId
        })
      )

      return response
    },
    async deleteAuth0Identity(parentId, provider, childId) {
      const response = await Common.delete(`/api/auth0/users/${encodeURIComponent(parentId)}/identities/${provider}/${childId}`)

      return response;
    },
  })
}


export function createApi(auth) {
  InitApiInstance(auth)

  return {
    install(app) {
      app.config.globalProperties.$api = ApiInstance
      app.provide('$api', ApiInstance)
      app.provide('$adminApi', AdminApiInstance)
    }
  }
}

export function useApi() {
  return ApiInstance
}

export function useAdminApi() {
  return AdminApiInstance
}

