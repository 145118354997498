<template>
  <div class="container">
    <Loading v-if="loadingMyData" title="Getting User Information" />
    <Loading v-if="submittingForm" title="Submitting" />

    <template v-if="isSmsUser">
      <phone-validation :value="smsUserPhoneNumber" @change="refreshAuth0Data" />
    </template>
    <template v-else>
      <phone-validation :value="formattedLinkedPhoneNumber" @change="refreshAuth0Data" />
    </template>

    <hr />

    <ContactForm v-model="form" :wizardMode="false" @submitForm="handleSubmit" />
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed, inject } from "vue";
import { useApi } from "../plugins/api";
import { useAuth0 } from "@auth0/auth0-vue";

import ContactForm from "../components/ContactForm.vue";

const $api = useApi();
const $auth0 = useAuth0();
const $telemetry = inject("$telemetry")

const form = reactive({
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  state: "",
  phone: "",
  org: "",
  dealerId: "",
});

const prevUserInfo = reactive({
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  state: "",
  phone: "",
  org: "",
  dealerId: "",
});

const loadingMyData = ref(false);
const submittingForm = ref(false);

const isSmsUser = computed(() => {
  const rawJson = $auth0?.user?.value?.rawJson || {};

  return rawJson.phone_verified === true
})

const smsUserPhoneNumber = computed(() => {
  const rawJson = $auth0?.user?.value?.rawJson || {};

  return rawJson?.phone_number
})

const linkedPhoneIdentity = computed(() => {
  const rawJson = $auth0?.user?.value?.rawJson || {};

  const identities = rawJson?.identities || [];
  const linkedIdentities = identities.filter(
    (i) => rawJson.user_id?.indexOf(i.userId) < 0
  );

  // add some metadata

  linkedIdentities.forEach((i) => {
    i.name =
      i.profileData?.email || i.profileData?.name || i.profileData?.first_name;

    i.identifier = i.profileData?.email || i.profileData?.phone_number;
  });

  const phoneIdentity =
    linkedIdentities.find((identity) => identity.connection === "sms") || null;

  return phoneIdentity;
});
const formattedLinkedPhoneNumber = computed(() => {
  return linkedPhoneIdentity.value?.profileData?.phone_number || "---";
});

const getMyDealer = async () => {
  loadingMyData.value = true;
  try {
    const response = await $api.getUserInformation();

    if (!response.userInformation) {
      return
    }

    const userInfo = {
      firstName: response.userInformation.first_name,
      lastName: response.userInformation.last_name,
      address: response.userInformation.address1,
      zip: response.userInformation.zip,
      city: response.userInformation.city,
      state: response.userInformation.state || "",
      phone: formattedLinkedPhoneNumber.value,
      org: response.userInformation.organization,
      dealerId: response.userInformation.dealer_id,
    };

    Object.assign(form, userInfo);
    Object.assign(prevUserInfo, userInfo);
  } catch (e) {
    $telemetry.error(e);
  }
  finally {
    loadingMyData.value = false;
  }
};

const handleSubmit = async () => {
  try {
    submittingForm.value = true;
    await $api.putUserInformation(form);
    Object.assign(prevUserInfo, form);
  } catch (e) {
    $telemetry.error(e);
  } finally {
    submittingForm.value = false;
  }
};

async function refreshAuth0Data() {
  try {
    loadingMyData.value = true
    await $auth0.getAccessTokenSilently({ cacheMode: "off" });
  }
  finally {
    loadingMyData.value = false
  }
}

onMounted(async () => {
  await getMyDealer();
});
</script>

<style lang="css" scoped>
.container {
  color: var(--fg0);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.phone-container {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--padding) var(--padding);
  align-items: center;
  height: 2rem;
}

.phone-container button {
  height: 100%;
  min-width: 3rem;
  padding: 0;
}

.phone-container input {
  height: 100%;
  width: 12rem;
  box-sizing: border-box;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .form-container {
    width: calc(100% - (2 * var(--padding)));
  }
}
</style>
