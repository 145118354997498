<template>
  <Teleport to="body">
    <div v-if="props.isOpen" class="modal-backdrop">
      <div class="modal-content">
        <slot></slot>
        <div
          :style="{ width: '100%', display: 'flex', justifyContent: 'center' }"
        >
          <template v-if="props.closeButton">
            <button @click="closeModal">close</button>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const props = defineProps({
  isOpen: Boolean,
  forBlock: Boolean,
  closeButton: Boolean,
});
const $auth0 = useAuth0();
const emit = defineEmits(["update:isOpen"]);

function closeModal() {
  emit("update:isOpen", false);
  if (props.forBlock) {
    logOut();
  }
}
function logOut() {
  $auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  width: 18rem;
  background: var(--bg2);
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
