<template>
  <span>
    <template v-if="editing">
      <select v-if="options" @change="submit" @blur="cancelEdit" @keydown.escape="cancelEdit" v-model="value" ref="selectRef">
        <option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <input
        v-else
        v-show="editing"
        v-model="value"
        @keyup.enter="submit"
        @blur="submit"
        @keydown.escape="cancelEdit"
        v-bind="inputProps"
        ref="theInput"
      />
    </template>
    <label v-show="!editing" class="thelabel" @click="edit">{{ label }}</label>
  </span>
</template>

<script setup>
import { computed, watch, ref } from "vue";
const props = defineProps({
  inputProps: {
    type: Object,
    required: false,
    default: () => ({ type: "text" }),
  },
  options: {
    type: Array,
    required: false,
    default: null,
  },
});

const value = defineModel();
const emit = defineEmits(["change"]);

const selectRef = ref(null)
const editing = ref(false);
const theInput = ref(null);

const label = computed(() => {
  if (props.options) {
    const item = props.options.find((option) => option.id === value.value);
    if(item) {
      return item.name
    }
  }

  return value.value?.length ? value.value : "...";
});
//focus input when clicked on
function edit() {
  editing.value = true;
  requestAnimationFrame(() => {
    if (theInput.value) {
      theInput.value.focus();
    }
  });
}

function cancelEdit() {
  editing.value = false
}

function submit() {
  editing.value = false;
  emit("change", value.value);
}

watch(() => [editing, selectRef.value], () => {
  console.log(editing.value, selectRef.value)
  if(selectRef.value && editing.value) {
    selectRef.value.focus()
  }
}, {immediate: true})
</script>

<style lang="css" scoped>
input:focus{
    outline: none;
}
.thelabel {
  cursor: pointer;
}

.thelabel:hover {
  text-decoration: underline;
}
</style>
