<template>
  <Loading v-if="$auth0.isLoading.value" title="Authenticating..." />
  <template v-else>
    <div v-if="showWallpaper" class="wallpaper" />

    <router-view />
  </template>
</template>

<script setup>
import { watch, computed, inject, ref, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute, useRouter } from "vue-router";

const $auth0 = useAuth0();
const $env = inject("$env");
const $telemetry = inject("$telemetry");
const route = useRoute();

const isAuthenticated = $auth0.isAuthenticated

const showWallpaper = computed(() => {
  return isAuthenticated.value || route.path === '/menu-login'
})

</script>

<style lang="css">
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(12, 22, 21);
  position: relative;
  z-index: 0;
  overflow: hidden;
}

@keyframes MattEffect {
  0% {
    transform: translate(0%, 0%) scale(1.4);
  }

  25% {
    transform: translate(5%, -10%) scale(1.6);
    /* Pan and zoom */
  }

  50% {
    transform: translate(15%, 5%) scale(1.5);
    /* Pan and zoom */
  }

  75% {
    transform: translate(-7%, -8%) scale(2.7);
    /* Pan and zoom */
  }

  100% {
    transform: translate(0%, 0%) scale(1.4);
    /* Return to original position */
  }
}

.wallpaper {
  animation: MattEffect 40s ease-in-out infinite;
  z-index: -1;
  will-change: transform;
  background-image: url('/topography.svg');
  background-color: rgb(12, 22, 21);
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* width: 95vw;
  height: 50vh; */
  /* color: var(--fg0); */
  /* object-fit: fill; */
  /* border: none; */
}
</style>
