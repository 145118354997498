<template>
    <Loading title="Redirecting..." />
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useAuth0 } from '@auth0/auth0-vue';
import { onMounted, inject } from "vue";


const $auth0 = useAuth0()
const $env = inject("$env")
const route = useRoute()
const router = useRouter()

onMounted(() => {
    if (route.query.error) {
        router.replace({
            name: "Login Menu", query: {
                callback: route.query.callback
            }
        })
        return
    }

    const target = route.query.target
    if (target === 'contact') {
        router.replace({ path: `/contact` })
        return
    }

    if (target === 'dealer') {
        router.replace({ path: `/dealer` })
        return
    }

    router.replace({ path: `/` })
})

// onMounted(async () => {
//     const { target, ...queryWithTargetRemoved } = route.query;

//     const queryParams = new URLSearchParams(queryWithTargetRemoved).toString();
//     let redirectUri = `${window.location.origin}/${route.query.target}?${queryParams}`;

//     alert('would redirect to? ' + redirectUri)

//     await $auth0.loginWithRedirect({
//         authorizationParams: {
//             prompt: 'none',
//             audience: $env.VUE_APP_AUTH0_API_AUDIENCE,
//             redirect_uri: redirectUri
//         }
//     })
// })
</script>

<style lang="css" scoped></style>
